import scrollMonitor from 'scrollmonitor';
import forEach from 'helpers/forEach';

const scrollAnimations = {
  elements: {
    animated: document.querySelectorAll('[data-scroll-animated]'),
    offset: 300,
  },
  handleClass: (watchItem, className) => {
    let animationClass =
      watchItem.getAttribute('data-scroll-animated') !== ''
        ? watchItem.getAttribute('data-scroll-animated')
        : 'scroll-animation';
    watchItem.classList.add(animationClass);
  },
  createWatcher: element => {
    const { offset } = scrollAnimations.elements;
    let watcher = scrollMonitor.create(element, -offset);
    watcher.enterViewport(() => {
      scrollAnimations.handleClass(watcher.watchItem);
    });
  },
  init: () => {
    const { animated } = scrollAnimations.elements;
    forEach(animated, (index, element) => {
      scrollAnimations.createWatcher(element);
    });
  },
};

export default scrollAnimations;
