import Flickity from 'flickity';
import forEach from 'helpers/forEach';
require('flickity/dist/flickity.min.css');
require('flickity-imagesloaded');

const Gallery = {
  elements: {
    galleries: document.querySelectorAll('[data-gallery]'),
  },
  createGallery: element => {
    new Flickity(element, {
      imagesLoaded: true,
      wrapAround: true,
      prevNextButtons: true,
      pageDots: false,
    });
  },
  init: () => {
    const { galleries } = Gallery.elements;
    forEach(galleries, (index, galleryElement) => {
      let element = document.getElementById(galleryElement.id);
      Gallery.createGallery(element);
    });
  },
};

export default Gallery;
