/* (SEMI) GENERIC TRACKING SCRIPT
 * 
 * This script is /intended/ to be standalone, but there is some VA specific code in here.
 * 
 * This script is the interface between the project and Google Tag Manager
 * 
 * The methods include:-
 * - Page view tracking
 * - Event tracking
 * - Ecommerce tracking
 * - Generic setting of key/value for insertion into GTM
 * 
 * Events can be tracked in 3 ways
 * - ELEMENT TAGGING : individual elements can be tagged in the markup
 *  -  To tag elements from markup use this format e.g.
 *
 *     <a href="/blah" data-event-category="{string: category name}" data-event-action="{string: action name}" data-event-label="{string: action label}">
 *
 *     This method is more simplistic and doesn't have the functionality to dynamcically generate content or run a function

 * - CALLED FROM SCRIPT : the tracking library can be accessed from within code block to fire events at specific points in the page lifecycle
 *  - using the tracking.trackEvent(category,action,label) method from within.
 *  
 * - EVENTS OVERLAY : this is where the event tracking is defined in the supporting project setup script. This is the project specific side of the tracking. See notes in that file
 *  
 * 
 * Form tracking is handled in this script for instances where the form does not have a unique url that is trackable (all forms in VA)
 * 
 * It is pretty messy, and possibly project specific. But it seems to work.
 * 
 * */
window.dataLayer = window.dataLayer || [];
let dataLayer = window.dataLayer;

import { eventList } from "./event_tracking.js";

// ripped off of jquery
function isFunction(obj) {

    // Support: Chrome <=57, Firefox <=52
    // In some browsers, typeof returns "function" for HTML <object> elements
    // (i.e., `typeof document.createElement( "object" ) === "function"`).
    // We don't want to classify *any* DOM node as a function.
    return typeof obj === "function" && typeof obj.nodeType !== "number";
};

export const tracking = {
  init() {

    /*
     * Bit of test script that will find anchors and the level at which the parent has an id. Used for enhanced link attribution
    
    $("a").each(function() {

	var a = this;
	var el = a;
	var level = 0;
	while( el && !el.id) {
level++;
el = el.parentNode;
}
(level === null || level > 5) && console.info( a, el ? level : null, el ? el.id : "null")
});

*/
    if (eventList && eventList.length) {
      this.bindEvents(eventList);
    }

    // this is for direct tagging of elements in the site. See example in MyAccountCTA.jsx
    this.setupElementTaggingTracking();

  },

  isDev: location.hostname.match(/localhost|equator/),
  log(...args) {
    if (this.isDev) {
      console.log(args[0]);
    }
  },

  setupElementTaggingTracking() {
    // this is for stuff that would require a rewrite to make click work. If you want to sort it out with react onclick handlers, be my guest
    document.addEventListener(
      'click',
      e => {
        if (e.target.matches('[data-event-category],[data-event-category] *')) {
          var element = e.target.closest('[data-event-category]');
          var cat = element.getAttribute('data-event-category');
          var act = element.getAttribute('data-event-action');
          var lab = element.getAttribute('data-event-label');
          this.trackEvent(cat, act, lab);
        }
      },
      false
    );
  },

  bindEvents(list) {
    var self = this;
    // filter the events by type
    var eventTypes = {};

    var blockSvgClasses = [];

    // loop through the events into event types
    list.forEach(e => {
      // get the event Type
      var type = e.eventTrigger || 'click';

      // check to see if we match a url (if it doesn't match then return, otherwise match all pages)
      if (e.url && !new RegExp(e.url, "i").test(location.pathname)) return;

      // put the * selector for the child matches
      // e.g. ".first,#second,.third .child" => ".first,.first *,#second,#second *,.third .child,.third .child *"
      e.origMatches = e.matches;
      e.matches = e.matches.replace(/([^,]+)(,)?/g, "$1,$1 *$2");

      // tag whether this is a function or not. To save processing in the click
      if (isFunction(e.eventCategory)) {
        e.eventCategoryFunction = e.eventCategory;
        e.hasFunction = true;
      } else {
        e.eventCategoryString = e.eventCategory;
      }
      if (isFunction(e.eventAction)) {
        e.eventActionFunction = e.eventAction;
        e.hasFunction = true;
      } else {
        e.eventActionString = e.eventAction;
      }
      if (isFunction(e.eventLabel)) {
        e.eventLabelFunction = e.eventLabel;
        e.hasFunction = true;
      } else if (e.eventLabel) {
        e.eventLabelString = e.eventLabel;
      }

      // assign the reference to the type array or create it
      var thisType = eventTypes[type] || (eventTypes[type] = []);

      // push it onto the array
      thisType.push(e);
    })

    // loop through the eventTypes and attach a binding to the document to catch the event bubble
    Object.keys(eventTypes).forEach(eventType => {
      // get the list associated with this event type
      var eventList = eventTypes[eventType];
      
      // attach the event
      document.addEventListener(eventType, function (e) {
        // this is the target element of what was clicked on
        var target = e.target;

        // loop the eventList to see if there is a match.
        eventList.forEach((trackingEventData,index) => {
          if (!trackingEventData) return;

          if (e.target.matches(trackingEventData.matches)) {

            // get the closest match "parent"
            // TODO: should only do this if there is a function
            var matchEl = e.target.closest(trackingEventData.origMatches);

            var cat = trackingEventData.eventCategoryString || trackingEventData.eventCategoryFunction(matchEl, e.target);
            var act = trackingEventData.eventActionString || trackingEventData.eventActionFunction(matchEl, e.target);
            var lab = trackingEventData.eventLabel && (trackingEventData.eventLabelString || trackingEventData.eventLabelFunction(matchEl, e.target));

            if (cat && act) {
              self.trackEvent(cat, act, lab);
            }

            if (trackingEventData.onlyOnce) {
              eventList[index] = null;
            }

          }
        });

      }, false);

    });

  },

  trackPageview: function (pageView, pageViewOptions) {

    // if there are pageview options APPEND and/or PREPEND, then we will take the pageview and glue it to the current window.location.pathname

    if (pageView && pageViewOptions) {
      // append will trump prepend. Not doing both.
      if (pageViewOptions & this.pageViewOptions.APPEND) {
        pageView = window.location.pathname + '/' + pageView;
      } else if (pageViewOptions & this.pageViewOptions.PREPEND) {
        pageView = '/' + pageView + '/' + window.location.pathname;
      }

      if (pageViewOptions & this.pageViewOptions.KEEP_QUERYSTRING) {
        pageView += window.location.search;
      }

    }

    if (pageView) {
      // get rid of any multiple slashes
      pageView = pageView.replace(/\/{2,}/g, '/');
    }

    var dlObject = {
      event: 'pageView',
	  pageView: pageView
    };

    dataLayer.push(dlObject);

    this.log(dlObject);
  },

  pageViewOptions: { // uses bitwise for matching. Why? It feels a bit .nety
    APPEND: 1,
    KEEP_QUERYSTRING: 2,
    PREPEND: 4
  },

  trackEvent(params) {
    let {eventCategory, eventAction, eventLabel} = params
    var eventData = {
      event: 'trackEvent',
      eventCategory,
      eventAction,
      eventLabel
    }; 

    dataLayer.push(eventData);
    this.log(eventData);
  },

  //==================================================================================================
  //	Singular Generic set dimension method
  //==================================================================================================
  setDataLayerKeyValue: function (key, value) {
    var data = {};
    data[key] = value;
    dataLayer.push(data);
  },

  // only track differences of the filter selected values. This will be populated as the filters are used
  // THIS WAS FOR VA, BUT COULD BE USEFUL FOR OTHER FILTER AREAS?
  tableFiltersSelected: {},

  trackTableFilter(filterName, filterTitles) {
    // check to see if this same for this page
    if (this.tableFiltersSelected[filterName] == filterTitles) return;

    // get where the filter is coming from based on the id of the react wrapper in the page
    var container = document.querySelector('[data-tracking-tag]');
    var tag = container ? container.getAttribute("data-tracking-tag") : "General filter";
    this.trackEvent(`Filter container - ${tag}`, `Filter name - ${filterName}`, `Filter value - ${filterTitles}`);

    this.tableFiltersSelected[filterName] = filterTitles;
  },

  trackTableButton(buttonText, className) {
    this.trackEvent('Timetable', `Timetable action class - ${className}`, `Timetable button - ${buttonText} : ${className}`);
  },
  
  clubFinderFilterTracked: {},

  trackClubFinderFilter(filterName) {
    if (this.clubFinderFilterTracked[filterName]) return;
    this.trackEvent('Club finder', 'Club finder filter', `Club finder filter selected - ${filterName}`);
    this.clubFinderFilterTracked[filterName] = true;
  },

  //==================================================================================================
  //	Send data for custom dimension
  //  when user is signed in
  //==================================================================================================
  // This isn't currently being used because it is being set in the trackingReady event
  // Candidate for deletion
  setUserId: function (userId) {
    // There are 2 parts to this:-
    // - Built-in Google "User Id" feature. This needs to be sent on every hit that there is a token available
    // - Setting a custom dimension to store the user id ("hit" level)
    // Both of these are set in Google Tag Manager

    // If the user comes in via a token, then they have already opted-in to user id tracking. Otherwise the token will be retrieved from a login
    this.setDataLayerKeyValue('userId', userId);
  }
}

// bit of code that I liked but am not using at the moment so didn't finish it
// check if it is an iframe to test (this is unusual occasion)
/*if (formData.iframeSelector) {
  // put an event listener of the load event. The iframe in question gets loaded by script, so can't target yet (race condition), but this script will fire on all load events e.g. iframes, scripts and images
  // going to check for the firing of the iframeSelector, and delay a bit
  // this code should be happening pretty quickly before any page elements load, but we should give it a short amount of time before deciding it is a proper submission
  // but we are somewhat having a punt a hoping the iframe loads quickly the first time. Maybe just accept that this code will run first before the iframe exists?
  var iframe = document.querySelector(formData.iframeSelector);
  console.info('%c' + iframe, 'color:red');
  setTimeout(() => {
    document.body.addEventListener("load", function (e) {
      if (e.target.matches(formData.iframeSelector)) {
        console.log(e.target);
      }
    }, true);
  }, 500);
  return;
}
*/