const Booking = {
  config: {
    form: document.querySelector('.booking'),
  },
  init: function () {
    const self = this;
    const { form } = self.config;
    if (form !== null) {
      const getNewCheckoutDate = (startDateString, endDateString) => {
        const addDays = (date, days) => {
          date.setDate(date.getDate() + days)
          return date
        }
        const formatToString = (date) => date.toISOString().split('T')[0]
        const startDate = new Date(startDateString)
        const endDate = new Date(endDateString)
        const startIsAfterEnd = startDate.getTime() >= endDate.getTime()
        return startIsAfterEnd ? formatToString( addDays(startDate, 1) ) : endDateString
      }
      const getDifferenceInDays = (startDateString, endDateString) => {
        const startDate = new Date(startDateString)
        const endDate = new Date(endDateString)
        const oneDay = 1000 * 60 * 60 * 24
        const diffInTime = endDate.getTime() - startDate.getTime()
        const diffInDays = Math.round(diffInTime / oneDay)
        return diffInDays;
      }
      const setNights = (startElement, endElement, nightsElement) => {
        nightsElement.value = getDifferenceInDays(startElement.value, endElement.value)
      }
      window.onChangeCheckIn = (startElement, endElement, nightsElement) => {
        endElement.flatpickr.setDate( getNewCheckoutDate(startElement.value, endElement.value) )
        endElement.flatpickr.set({minDate: startElement.value, defaultDate: startElement.value})
        setNights(startElement, endElement, nightsElement)
      }
      window.onChangeCheckOut = (startElement, endElement, nightsElement) => {
        setNights(startElement, endElement, nightsElement)
      }
    }
  },
};

export default Booking;
