import { tracking } from './generic_tracking';

const trimString = str => {
  return str.trim();
};

export class TrackingEvents {
  constructor() {
    this.elements = {
      bookNowBtn: document.querySelector('#book-now-btn') || null,
      giftVoucherBtn: document.querySelector('#gift-vouchers-btn') || null,
      menuBtn: document.querySelector('#menu-toggle-btn') || null,
      scrollBtn: document.querySelector('#scroll-down-btn') || null,
      carousalNextBtns:
        document.querySelectorAll('.flickity-prev-next-button.next') || [],
      carousalPrevBtns:
        document.querySelectorAll('.flickity-prev-next-button.previous') || [],
      carsoualHeroBtns:
        document.querySelectorAll('.has-carousel .text-image__block-button') ||
        [],
      tileGridPanels: document.querySelectorAll('.tile__grid-item') || [],
      videoPanels: document.querySelectorAll('.video-panel') || [],
      blockPanels: document.querySelectorAll('.cta__block') || [],
      filterBtns: document.querySelectorAll('.button--filter') || [],
      iframeBtns:
        document.querySelectorAll(
          '.text-image__block-button[data-modal-target]'
        ) || [],
      blockLinks:
        document.querySelectorAll('.text-image__block-overlay a') || [],
      ctaComponents: document.querySelectorAll('.cta-component') || [],
    };

    this.init();
  }

  init() {
    let {
      bookNowBtn,
      giftVoucherBtn,
      menuBtn,
      scrollBtn,
      carousalNextBtns,
      carousalPrevBtns,
      tileGridPanels,
      videoPanels,
      blockPanels,
      filterBtns,
      iframeBtns,
      blockLinks,
      ctaComponents,
    } = this.elements;

    //----------------------------------------------
    const getParentElement = ele => {
      return { parent: ele.parentElement };
    };

    const getElementContent = ele => {
      return { content: ele.innerHTML };
    };

    const currentPage = () => {
      let url = window.location;
      return { path: url.pathname, hash: url.hash };
    };

    //----------------------------------------------
    if (!!bookNowBtn) {
      bookNowBtn.addEventListener('click', function() {
        tracking.trackEvent({
          eventCategory: `Header CTA buttons`,
          eventAction: `Header CTA`,
          eventLabel: `Book Room: ${trimString(currentPage().path)}`,
        });
      });
    }

    if (!!giftVoucherBtn) {
      giftVoucherBtn.addEventListener('click', function() {
        tracking.trackEvent({
          eventCategory: `Header CTA buttons`,
          eventAction: `Header CTA`,
          eventLabel: `Offers: ${trimString(currentPage().path)}`,
        });
      });
    }

    if (!!menuBtn) {
      menuBtn.addEventListener('click', function() {
        tracking.trackEvent({
          eventCategory: `Header CTA buttons`,
          eventAction: `Menu`,
          eventLabel: `Menu (panel reveal)`,
        });
      });
    }

    if (!!scrollBtn) {
      scrollBtn.addEventListener('click', function() {
        tracking.trackEvent({
          eventCategory: `Banner`,
          eventAction: `Banner`,
          eventLabel: `Down Arrow (Header)`,
        });
      });
    }

    if (carousalNextBtns.length > 0) {
      carousalNextBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          tracking.trackEvent({
            eventCategory: `Carousel Panel`,
            eventAction: `Carousel Btn`,
            eventLabel: `Carousal NEXT: panel ${getParentElement(
              this
            ).parent.getAttribute('id')} - page ${currentPage().path}`,
          });
        });
      });
    }

    if (carousalPrevBtns.length > 0) {
      carousalPrevBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          tracking.trackEvent({
            eventCategory: `Carousel Panel`,
            eventAction: `Carousel Btn`,
            eventLabel: `Carousal PREV: panel ${getParentElement(
              this
            ).parent.getAttribute('id')} - page ${currentPage().path}`,
          });
        });
      });
    }

    if (tileGridPanels.length > 0) {
      tileGridPanels.forEach(panel => {
        let btn = panel.querySelector('.tile__grid .button--white');
        let header =
          panel.querySelector('.cta__heading') ||
          panel.querySelector('.tile__grid .button--white');
        if (!!btn) {
          btn.addEventListener('click', function() {
            tracking.trackEvent({
              eventCategory: `Grid Panel`,
              eventAction: `Grid`,
              eventLabel: `Grid link clicked on: ${trimString(
                header.innerHTML
              )} - page ${currentPage().path}`,
            });
          });
        }
      });
    }

    if (blockPanels.length > 0) {
      blockPanels.forEach(panel => {
        let btn = panel.querySelector('.button--line');
        let header =
          panel.querySelector('.detail__heading') ||
          panel.querySelector('.button--line');
        if (!!btn) {
          btn.addEventListener('click', function() {
            tracking.trackEvent({
              eventCategory: `Panel`,
              eventAction: `CTA button`,
              eventLabel: `Panel: ${trimString(header.innerHTML)} - page ${
                currentPage().path
              }`,
            });
          });
        }
      });
    }

    if (videoPanels.length > 0) {
      videoPanels.forEach(panel => {
        let btn = panel.querySelector('.button--play-video');
        let header =
          panel.querySelector('.heading_h2') ||
          panel.querySelector('.button--play-video');
        if (!!btn) {
          btn.addEventListener('click', function() {
            tracking.trackEvent({
              eventCategory: `Video`,
              eventAction: `Video open`,
              eventLabel: `Video open panel: ${trimString(
                header.innerHTML
              )} - page ${currentPage().path}`,
            });
          });
        }
      });
    }

    if (filterBtns.length > 0) {
      filterBtns.forEach((btn) => {
        btn.addEventListener('click', function () {
          const ecommerceData = btn.dataset.ecommerce;
          ecommerceData && dataLayer?.push(JSON.parse(ecommerceData));
          tracking.trackEvent({
            eventCategory: `Offers`,
            eventAction: `Offer category`,
            eventLabel: `Offer category - ${trimString(
              getElementContent(this).content
            )}`,
          });
        });
      });
    }

    if (iframeBtns.length > 0) {
      iframeBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          tracking.trackEvent({
            eventCategory: `Booking window`,
            eventAction: `Iframe open`,
            eventLabel: `Booking window open: ${trimString(
              getParentElement(this).parent.querySelector('.heading_h2')
                .innerHTML
            )}`,
          });
        });
      });
    }

    if (blockLinks.length > 0) {
      blockLinks.forEach(btn => {
        btn.addEventListener('click', function(e) {
          tracking.trackEvent({
            eventCategory: `Block Carousel`,
            eventAction: `Block overlay`,
            eventLabel: `Block overlay link for: ${trimString(
              getParentElement(this).parent.querySelector('.heading_h2')
                .innerHTML
            )}`,
          });
        });
      });
    }

    if (ctaComponents.length > 0) {
      ctaComponents.forEach(component => {
        let btn = component.querySelector('.cta-button');
        let title = component.querySelector('.cta-title');
        let titleText = btn && btn.innerHTML ? btn.innerHTML : '';
        if (title && title.innerHTML) {
          titleText = title.innerHTML;
        }

        btn && btn.addEventListener('click', function(e) {
          tracking.trackEvent({
            eventCategory: `Cta Button`,
            eventAction: `Click`,
            eventLabel: `Cta link for: ${trimString(titleText)} - page ${
              currentPage().path
            }`,
          });
        });
      });
    }
    //----------------------------------------------
  }
}
