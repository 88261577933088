import forEach from 'helpers/forEach';

function smoothScroll(element) {
  document.querySelector(element).scrollIntoView({
    behavior: 'smooth',
  });
}

export default function scrollLinks() {
  const scrollElements = document.querySelectorAll('[data-scroll]');
  forEach(scrollElements, function(index, link) {
    link.addEventListener('click', function(e) {
      e.preventDefault();
      const element = link.getAttribute('data-scroll');
      smoothScroll(element);
    });
  });
}
