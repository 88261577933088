import forEach from 'helpers/forEach';
import { disableScroll, enableScroll } from 'helpers/disableScroll';


const MainMenu = {
  elements: {
    menu: document.querySelector('.main__menu'),
    menuToggles: document.querySelectorAll('.js-toggle-menu'),
    menuItems: document.querySelectorAll('.main__menu-item-link'),
    menuColumns: document.querySelectorAll('.main__menu-column'),
    hasSubMenuLinks: document.querySelectorAll('.has-submenu'),
    isOpen: false,
  },
  closeMenu() {
    let { menu, isOpen } = this.elements;
    document.querySelector('html').style.overflowY = 'auto'
    this.elements.isOpen = false
    menu.classList.remove('main__menu--show');
  },
  toggleMenu: function() {
    const { menuToggles, menu, overlay } = this.elements;
    const _this = this;

    forEach(menuToggles, function(index, link) {

      link.addEventListener('click', function(e) {
        e.preventDefault();

        _this.elements.isOpen = !_this.elements.isOpen;
        document.querySelector('html').style.overflowY = _this.elements.isOpen ? 'hidden' : 'auto'

        menu.classList.toggle('main__menu--show');
        disableScroll();
        if (_this.elements.isOpen) {
          _this.resetMenu();
          enableScroll();
        }
      });
    });
  },
  mobileToggle() {
    const { hasSubMenuLinks } = this.elements;
    forEach(hasSubMenuLinks, function(index, link) {
      link.addEventListener('click', function(e) {
        e.preventDefault();
        this.parentElement.nextElementSibling.classList.toggle('show--menu');
      });
    });
  },
  toggleSubmenu: function() {
    const { menuItems, menuColumns } = this.elements;
    const _this = this;
    forEach(menuItems, function(index, link) {
      link.addEventListener('click', function(e) {
        const lastColumn = menuColumns[menuColumns.length - 1];
        const currentColumn = this.closest('.main__menu-column');
        const hasSubmenu = this.classList.contains('has-submenu');
        const subMenu = this.parentElement.nextElementSibling;
        const isActive = this.classList.contains('link--active');

        // if there is a submenu toggle it
        if (isActive) {
          e.preventDefault();
          this.classList.toggle('link--active');
          subMenu.classList.toggle('column--show');
        } else if (hasSubmenu) {
          e.preventDefault();

          // Find all other links that are active, remove and make this link active

          const activeLinks = currentColumn.querySelectorAll('.link--active');

          forEach(activeLinks, function(index, link) {
            link.classList.remove('link--active');
          });

          this.classList.add('link--active');

          // Find all other columns that are active, remove and make this column active
          const activeColumns = document.querySelectorAll('.column--show');
          forEach(activeColumns, function(index, column) {
            if (column !== currentColumn) {
              column.classList.remove('column--show');
            }
          });

          subMenu.classList.add('column--show');
        }
      });
    });
  },
  toggleColumns: function(currentColumn) {
    const { menuColumns } = this.elements;
    forEach(menuColumns, function(index, column) {
      if (column !== currentColumn) {
        column.classList.remove('column--show');
      }
    });
  },
  resetMenu: function() {
    const { menuItems, menuColumns } = this.elements;
    forEach(menuItems, function(index, link) {
      link.classList.remove('link--active');
    });

    forEach(menuColumns, function(index, link) {
      link.classList.remove('column--show');
    });
  },
  init: function() {
    const isMenuOpen = document.querySelector('.main__menu--show') !== null;
    if (this.elements.menu !== null) {
      this.mobileToggle();
      this.toggleMenu();
      this.toggleSubmenu();

      document
        .querySelector('.main__menu-close-overlay')
        .addEventListener('click', function() {
          MainMenu.closeMenu();
        });
    }
  },
};

export default MainMenu;
