import { tracking } from "../tracking/generic_tracking";
import Flickity from 'flickity';
import forEach from 'helpers/forEach';
require('flickity/dist/flickity.min.css');
require('flickity-imagesloaded');

window.Flickity = Flickity;

const trimString = (str) => {
  return str.trim()
}

const Carousel = {
  elements: {
    carousels: document.querySelectorAll('[data-carousel]'),    
  },
  createCarousel: (element, options) => {
    // Get content elements, if any
    let contentContainer = element.closest('.content-container');
    let hasContentContainer = contentContainer !== null;
    let slidesContent = hasContentContainer
      ? contentContainer.querySelectorAll('[class*=slide-content]')
      : null;

    // Get additonal slide options
    let slideOptions = JSON.parse(options);

    let slideDefaults = {
      imagesLoaded: true,
      on: {
        change: index => {
          // handle the content visibility when the slide changes
          if (slidesContent !== null && contentContainer !== null) {
            forEach(slidesContent, (index, slide) => {
              slide.classList.remove('show');
            });

            let currentSlide = contentContainer.querySelector(
              `.slide-content-${index + 1}`
            );
            currentSlide.classList.add('show');
          }
        },
        ready: () => {
          // show the content initially
          if (hasContentContainer) {
            contentContainer
              .querySelectorAll('[class*=slide-content]')[0]
              .classList.add('show');
          }
        },
      },
    };
    const customOptions = { ...slideOptions, ...slideDefaults };
    let ele = new Flickity(element, customOptions);

    

    // tracks dot press
    document.querySelectorAll('.flickity-page-dots .dot').forEach(dot => {      
      dot.addEventListener('click', () => {
          tracking.trackEvent({ 
            eventCategory: `Carousel Panel`, 
            eventAction: `Carousel dot`, 
            eventLabel: `${dot.getAttribute('aria-label')}`
          });         
      })
    })

    // tracking event on slide change
    ele.on( 'change', (index) => {            
      if(!!contentContainer){
        tracking.trackEvent({ 
          eventCategory: `Carousel Panel`, 
          eventAction: `Carousel change`, 
          eventLabel: `CTA Button: ${trimString(contentContainer.querySelector('.heading_h2').innerHTML)} - to slide ${index + 1}`
        });  
      }
    })

    // tracking event on slide change
    ele.on( 'pointerDown', (index) => {            
        tracking.trackEvent({ 
          eventCategory: `Carousel Panel`, 
          eventAction: `Carousel pointerDown`, 
          eventLabel: `Drag/finger down`
        });  
    })

    // tracking event on slide change
    ele.on( 'pointerUp', (index) => {           
        tracking.trackEvent({ 
          eventCategory: `Carousel Panel`, 
          eventAction: `Carousel pointerUp`, 
          eventLabel: `Drag/pointer up`
        });  
    })    



  },
  init: () => {
    const { carousels } = Carousel.elements;
    forEach(carousels, (index, carousel) => {
      let options = carousel.getAttribute('data-options');
      let element = document.getElementById(carousel.id);
      Carousel.createCarousel(element, options);
    });
  },
};

export default Carousel;
