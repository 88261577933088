var Froogaloop=function(){function e(a){return new e.fn.init(a)}function g(a,c,b){if(!b.contentWindow.postMessage)return!1;a=JSON.stringify({method:a,value:c});b.contentWindow.postMessage(a,h)}function l(a){var c,b;try{c=JSON.parse(a.data),b=c.event||c.method}catch(e){}"ready"!=b||k||(k=!0);if(!/^https?:\/\/player.vimeo.com/.test(a.origin))return!1;"*"===h&&(h=a.origin);a=c.value;var m=c.data,f=""===f?null:c.player_id;c=f?d[f][b]:d[b];b=[];if(!c)return!1;void 0!==a&&b.push(a);m&&b.push(m);f&&b.push(f);
return 0<b.length?c.apply(null,b):c.call()}function n(a,c,b){b?(d[b]||(d[b]={}),d[b][a]=c):d[a]=c}var d={},k=!1,h="*";e.fn=e.prototype={element:null,init:function(a){"string"===typeof a&&(a=document.getElementById(a));this.element=a;return this},api:function(a,c){if(!this.element||!a)return!1;var b=this.element,d=""!==b.id?b.id:null,e=c&&c.constructor&&c.call&&c.apply?null:c,f=c&&c.constructor&&c.call&&c.apply?c:null;f&&n(a,f,d);g(a,e,b);return this},addEvent:function(a,c){if(!this.element)return!1;
var b=this.element,d=""!==b.id?b.id:null;n(a,c,d);"ready"!=a?g("addEventListener",a,b):"ready"==a&&k&&c.call(null,d);return this},removeEvent:function(a){if(!this.element)return!1;var c=this.element,b=""!==c.id?c.id:null;a:{if(b&&d[b]){if(!d[b][a]){b=!1;break a}d[b][a]=null}else{if(!d[a]){b=!1;break a}d[a]=null}b=!0}"ready"!=a&&b&&g("removeEventListener",a,c)}};e.fn.init.prototype=e.fn;window.addEventListener?window.addEventListener("message",l,!1):window.attachEvent("onmessage",l);return window.Froogaloop=
window.$f=e}();

export class VJSVideo {
  constructor(ele){      
    this.ele = ele;
    this.type = ele.getAttribute('type')
    this.videoId = ele.getAttribute('videoId')
    this.fallbackImage = ele.getAttribute('fallbackImage')
    this.loop = (!!this.ele.getAttribute('loop')) ? this.ele.getAttribute('loop')  === 'loop' || this.ele.getAttribute('loop')  === 'true' : false
    this.autoplay = (!!this.ele.getAttribute('autoplay')) ? this.ele.getAttribute('autoplay')  === 'autoplay' || this.ele.getAttribute('autoplay')  === 'true' : false
    this.controls = (!!this.ele.getAttribute('controls')) ? this.ele.getAttribute('controls')  === 'controls' || this.ele.getAttribute('controls')  === 'true' : false
    this.muted = this.autoplay ? true : (!!this.ele.getAttribute('muted')) ? this.ele.getAttribute('muted')  === 'muted' || this.ele.getAttribute('muted')  === 'true' : false
    this.playingInBackground = ele.parentNode.classList.contains('video-fullscreen-bg')    
    this.playingInCard =  ele.parentNode.classList.contains('video-card')   
    this.playingInElement = this.playingInBackground || this.playingInCard

    // split youtube url
    let _v = this.videoId.split('/')    
    this.videoId = _v[_v.length - 1]

    this._div;
    this.init();

  }

  init(){
    let {ele, type, videoId, fallbackImage, loop, autoplay, controls, muted, playingInElement, playingInCard, _div} = this;
    
    let _customBtn = ``;
        _customBtn += `
        <div class='video-fallbackimage--container'>
          <i class="video-fallbackimage--btn fas fa-play-circle fa-3x  ${playingInCard ? 'margin--150t white' : 'va-red'}"></i>
        </div>         
        `       
    let externalBtn = ele.parentNode.parentNode.querySelector('.video-external-btn')
    if(!!externalBtn){
      externalBtn.style.zIndex =  2;
      externalBtn.addEventListener('click', () => {
        this.loadVideo(true)
        ele.parentNode.parentNode.removeChild(externalBtn)
      })
    }


    if( !(!!fallbackImage) ){
      this.loadVideo();
    } else{
      _div = document.createElement('div')
      _div.innerHTML = `
        <div class="${playingInElement ? 'video-ready' : 'video-container video-ready'}" >
          <div class='video-fallbackimage--image' style='background: url(${fallbackImage}) no-repeat center center; background-size: cover;'>
          </div>  
          ${_customBtn}
        </div>
        `

        
        ele.parentNode.insertBefore( _div, ele );     
        ele.parentNode.querySelectorAll('.video-ready').forEach(_ele => {  
          _ele.querySelectorAll('.video-fallbackimage--btn').forEach(btn => {
            btn.onclick = () => {
              _ele.parentNode.removeChild(_ele)            
              this.loadVideo(true)
            }
          })
        })
    }    
  }

  loadVideo(forceAuto = false){    
    let {ele, type, videoId, fallbackimage, loop, autoplay, controls, muted, playingInElement, _div} = this;
    

    switch(type) {
        case 'venmo':        
          controls = autoplay ? false : controls; 
          if(forceAuto){
            loop = true
            autoplay = true
            controls = true
          }

          _div = document.createElement('div')
          _div.innerHTML = `
            <div class="${playingInElement ? '' : 'video-container'}" >
              ${autoplay ? 
                `
                <iframe id='vimeoplayer' src="https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1?muted=1&control=false&background=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow=autoplay>
                ` 
                : 
                `
                <iframe id='vimeoplayer' src="https://player.vimeo.com/video/${videoId}?autoplay=0&muted=1&control=true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen >
                `
              }
            </div>
          `
          ele.parentNode.insertBefore( _div, ele );

          setTimeout(() => {
            let iframe = document.getElementById('vimeoplayer'),
                player = $f(iframe);                
                window.vimeoplayer = player;
          }, 1)

        break;
        case 'youtube':          
          if(forceAuto){
            loop = true
            autoplay = true
            controls = false
          }      
          

          _div = document.createElement('div')
          _div.innerHTML = `
            <div class="${playingInElement ? '' : 'video-container'}" >      
              <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/${videoId}?autoplay=${autoplay ? 1 : 0}&loop=${loop ? 1 : 0}&autopause=${loop ? 1 : 0}&controls=${controls ? 1 : 0}&mute=${muted ? 1 : 0}&fs=1&enablejsapi=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen >
            </div>
          `
        
          ele.parentNode.insertBefore( _div, ele );       

          setTimeout(() => {
              let player = document.getElementById('ytplayer');
              window.ytplayer = player;
          }, 1)          
          

        break;
        case 'local':
          if(forceAuto){
            loop = false
            autoplay = true
            controls = true
          }    
          _div = document.createElement('div')
          _div.innerHTML = `
            <div class="${playingInElement ? '' : 'video-container'}" >
              <video ${autoplay ? 'autoPlay' : ''}  ${muted ? 'muted' : ''}  ${loop ? 'loop' : ''} ${controls ? 'controls' : ''}>              
                    <source src='${videoId}' type="video/mp4" />        
              </video> 
            </div>
          `
          ele.parentNode.insertBefore( _div, ele );                  
        break;
        default:        
    } 
  }

}