
import 'url-search-params-polyfill';
import queryString from 'query-string';

let FilterElements = {
  elements: {
    filterButtons: document.querySelectorAll('[data-filter]'),
    filterItems: document.querySelectorAll('[data-filter-item]'),
    loadMoreButton: document.querySelector('.js-load-more'),
    currentFilter: queryString.parse(location.search).search || null,
    items: [],
    showAmount: 6,
  },
  
  init: function() {
    const { filterButtons, currentFilter, loadMoreButton, showAmount, filterItems } = this.elements;
    let validItems = []

    
    filterButtons.forEach(button => {
      let filter = button.getAttribute('data-filter')   
      validItems.push(filter)   
      button.addEventListener('click', (e) => {      
        e.preventDefault()            
        this.applyFilter(filter)
      });
    })

    filterItems.forEach(ele => {            
      this.elements.items.push(ele)
    })
    
    if(!!loadMoreButton){
      loadMoreButton.addEventListener('click', (e) => {
        e.preventDefault()
        let {currentFilter} = this.elements;     
        this.elements.showAmount += showAmount;
        this.applyFilter(currentFilter)      
      });  
        
      let validFilter
      if(!!currentFilter){
        validFilter = validItems.toString().includes(currentFilter.toLowerCase())
      }

      const urlParams = new URLSearchParams(window.location.search);
      let urlDefault = urlParams.get('offer');

      this.applyFilter(validFilter ? currentFilter.toLowerCase() : urlDefault ? urlDefault : 'all')
    }
  },  

  hideall: function(){
    let { items } = this.elements;

    items.forEach(item => {
      this.toggleVisibility(item, false)
    })    
  },


  loadmoreVisibility: function(isVisible){
    const { loadMoreButton } = this.elements;
    loadMoreButton.classList[!isVisible ? 'remove' : 'add']('button--hide')
  },

  toggleVisibility: function(element, isVisible) {
    element.classList[isVisible ? 'remove' : 'add']('hide')
  },  

  applyFilter: function(filter = 'all'){
    let { showAmount, items } = this.elements;
    this.elements.currentFilter = filter
    this.hideall()

    if (filter === 'all') {
      items.forEach((item, index) => {
        this.toggleVisibility(item, index < showAmount)
      })
      
      this.loadmoreVisibility(items.length - 1 < showAmount)
    } else {      
      let filtered = items.filter(item => {
        return item.getAttribute('data-filter-item') === filter
      })  
      filtered.forEach((item, index) => {
        this.toggleVisibility(item, index < showAmount)
      })

      this.loadmoreVisibility(filtered.length - 1 < showAmount)
    }
  },
};

export default FilterElements;
