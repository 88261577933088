import forEach from 'helpers/forEach';
import { disableScroll, enableScroll } from 'helpers/disableScroll';

const Modal = {
  elements: {
    modalButtons: document.querySelectorAll('[data-modal]'),
  },

  createModal: (button, modal) => {
    const close = modal.querySelector('.js-close-modal');
    
    button.addEventListener('click', e => {
      e.preventDefault();
      Modal.toggleModal(modal);
    });

    close.addEventListener('click', e => {
      e.preventDefault();      
      if(!!modal.querySelectorAll('.video-player-iframe')[0]){
        modal.querySelectorAll('.video-player-iframe')[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
      }


      if(!!window.vimeoplayer){  
        window.vimeoplayer.api('pause')              
      }
      if(!!window.ytplayer){
        window.ytplayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
      }

      modal.classList.add('modal--closing');
      modal.classList.remove('modal--open');
      enableScroll();
    });
  },

  toggleModal: modal => {
    modal.classList.remove('modal--closing');
    modal.classList.toggle('modal--open');
    disableScroll();
  },

  init: () => {
    const { modalButtons } = Modal.elements;
    forEach(modalButtons, (index, button) => {
      let modal = document.getElementById(
        button.getAttribute('data-modal-target')
      );
      Modal.createModal(button, modal);
    });
  },
};

export default Modal;
