/* PROJECT SETUP
 * 
 * This file is /INTENDED/ to be project specific that feeds into the tracking.js script. However some non-generic code
 * has crept into the generic script.
 * 
 **** EVENTS OVERLAY ****
 *  
 * This is the list that will be used to initiate the event tracking layer over the top of the solution.
 * It is done in this way so there is less interference with the site code, and there should hopefully be less 
 * fighting with the CMS framework. This is for areas that could be difficult to get to direct script or tagging e.g. generic anchor components that can't be tagged
 * 
 * event parameters are referring to GA events tracking parameters
 * 
 * eventList objects are in the format
 * 
 
{
    url: '{OPTIONAL string: regexp for url matching}'
	matches: '{string: css selector}',
    eventName: '{OPTIONAL string: dom event name}',
    eventCategory: '{string: category name OR function }',
    eventAction: '{string: action name OR function}',
    eventLabel: '{OPTIONAL string: label name OR function}',
    testingSampleUrl: '{OPTIONAL string: url}',
    testingNotes:  '{OPTIONAL string: test description}'
}

 * url: this is the page to look for events on. Using this will make the script a bit more efficient (maybe), rather than trying to match for something doesn't exist. If this is left blank it will match against all pages
 * matches: this is the css selector of the top-level element you wish to match. Children will be automatically matched against
 * eventName: the dom event name to match against. e.g. mousedown. Defaults to 'click'
 * eventCategory: This refers to the 'category' that is recorded in GA. It can be string value or a function reference (see below)
 * eventAction: This refers to the 'action' that is recorded in GA. It can be string value or a function reference (see below)
 * eventLabel: This refers to the 'label' that is recorded in GA. It can be string value or a function reference (see below)
 * testingSampleUrl: Just trying to do something to help testing (probably the future) where automation could run the script and test whether the items exists. Got caught out where a css value had changed once. This could also serve as an example of where to find the the tracking for future devs
 * testingNotes: notes to describe the how to test this and what purpose does it serve

 * For each of the category, action and label, a function can be used to retrieve text that is dynamically rendered.
 * The function parameters will include the element that was clicked on, and the parent element that was matched
 * it must return a string value e.g.
 * 
   eventCategory: function(matchEl, targetEl) {
       return matchEl.textContent;
   }
 * 
 
*/

/* ALL OF THE BELOW ARE EXAMPLES FROM VIRGIN ACTIVE AND NEED TO BE REMOVED, BUT MIGHT SHOW HOW TO SET ONE UP */

export var eventList = [
  {
    matches: '#site-header .logo-container img, #site-header .logo-container svg',
    eventCategory: 'Page header',
    eventAction: 'VA logo home link',
    testingSamplePage: '/',
    testingNotes: 'Clicking on the top nav home link'
  },
  {
    matches: '#site-header .login-container--items a',
    eventCategory: 'Page header',
    eventAction: 'VA login links',
    eventLabel(m, t) {
      return `Login links - ${m.textContent.trim()}`;
    },
    testingSamplePage: '/',
    testingNotes: 'Clicking on the login links'
  },
  {
    url: '^\/$',
    matches: '#home-container .button-container a, #home-container .button-container button',
    eventCategory: 'Home page',
    eventAction: 'Main CTA',
    eventLabel(m, t) {
      return `Main CTA - ${m.textContent.trim()}`;
    },
    testingSamplePage: '/',
    testingNotes: 'Clicking on the link under the search box'
  },
  {
    url: '^\/$',
    matches: '#home-container .searchbar input',
    eventCategory: 'Home page',
    eventAction: 'Search club',
    eventLabel: 'Search club click',
    onlyOnce: true,
    testingSamplePage: '/',
    testingNotes: 'Clicking on search box'
  },
  {
    url: '^\/$',
    matches: '.va__autocomplete__listItem',
    eventCategory: 'Home page',
    eventAction: 'Search club',
    eventLabel(m, t) {
      return 'Search club lookup - ' + m.textContent.trim();
    },
    onlyOnce: true,
    testingSamplePage: '/',
    testingNotes: 'Clicking on search box'
  },
  {
    matches: '.navbar-desktop__item',
    eventCategory: 'Page header',
    eventAction: 'Nav bar',
    eventLabel: function (m, t) {
      return `Nav bar - ${m.textContent.trim()}`;
    },
    testingSamplePage: '/',
    testingNotes: 'Clicking on the top nav items'
  },
  {
    matches: '#site-header .menu-button',
    eventCategory: 'Page header',
    eventAction: 'Main menu toggle',
    eventLabel: function (m, t) {
      var open = !this.isOpen;
      this.isOpen = open;
      return open ? 'Main menu open' : 'Main menu closed';
    },
    testingSamplePage: '/',
    testingNotes: 'Opening and closing the main menu from the burger'
    //blockSvg: true
  },
  {
    matches: '.navbar-desktop__item',
    eventCategory: 'Page header',
    eventAction: 'Nav bar',
    eventLabel: function (m, t) {
      return `Nav bar - ${m.textContent.trim()}`;
    },
    testingSamplePage: '/',
    testingNotes: 'Clicking on the top nav items'
  },
  {
    matches: '.footer--icon-container [data-sf-socialshareoption] a', // not google plus, it throws an error. Investigate perhaps document.activeElement hack for the future
    eventCategory: 'Page Footer',
    eventAction: 'Social link',
    eventLabel: function (m, t) {
      return m.parentNode.getAttribute("data-sf-socialshareoption") + " page";
    },
    testingSampleUrl: '/',
    testingNotes: 'links to social pages in footer'
  },
  {
    matches: '#downloadApps a img',
    eventCategory: 'Page Footer',
    eventAction: 'Download VA app',
    eventLabel: function (m, t) {
      return `Download ${m.getAttribute("title").match(/google/i) ? 'Android' : 'Apple'} app`;
    },
    testingSampleUrl: '/',
    testingNotes: 'links to download app in footer'
  }
  
  // Telephone match is in GTM (easier to do with reg exp on the click url)
  // external link is in GTM
  // Download link is in GTM
  // Join us links are in GTM

];