// https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/

// Usage:
// optionally change the scope as final parameter too, like ECMA5
// const myNodeList = document.querySelectorAll('li');
// forEach(myNodeList, function (index, value) {
//     console.log(index, value); // passes index + value back!
// });

export default function forEach(array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]);
  }
}
