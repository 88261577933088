const Header = {
  elements: {
    header: document.querySelector('.site__header'),
    logo: document.querySelector('.site__header-logo'),
  },
  toggleHeaderClass: () => {
    const { header, logo } = Header.elements;
    let scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition >= 80) {
      header.classList.add('sticky--style');
      logo.classList.add('logo--sticky');
    } else {
      header.classList.remove('sticky--style');
      logo.classList.remove('logo--sticky');
    }
  },
  watchScroll: () => {
    window.addEventListener('scroll', function() {
      Header.toggleHeaderClass();
    });
  },
  init: () => {
    if (Header.elements.header !== null) {
      Header.watchScroll();
    }
  },
};

export default Header;
